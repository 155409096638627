"use strict";

var compose = require('redux').compose;
var logOnly = require('./logOnly');

exports.__esModule = true;
exports.composeWithDevTools = (
  process.env.NODE_ENV === 'production' ? logOnly.composeWithDevTools :
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ :
      function() {
        if (arguments.length === 0) return undefined;
        if (typeof arguments[0] === 'object') return compose;
        return compose.apply(null, arguments);
      }
);

exports.devToolsEnhancer = (
  process.env.NODE_ENV === 'production' ? logOnly.devToolsEnhancer :
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION__ ?
      window.__REDUX_DEVTOOLS_EXTENSION__ :
      function() { return function(noop) { return noop; } }
);

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

function unsupportedProp(props, propName, componentName, location, propFullName) {
  /* istanbul ignore if */
  if (process.env.NODE_ENV === 'production') {
    return null;
  }

  var propFullNameSafe = propFullName || propName;

  if (typeof props[propName] !== 'undefined') {
    return new Error("The property `".concat(propFullNameSafe, "` is not supported. Please remove it."));
  }

  return null;
}

var _default = unsupportedProp;
exports.default = _default;
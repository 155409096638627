'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _util = require('./util');

var globals = ['EventTarget', 'Window', 'Node', 'ApplicationCache', 'AudioTrackList', 'ChannelMergerNode', 'CryptoOperation', 'EventSource', 'FileReader', 'HTMLUnknownElement', 'IDBDatabase', 'IDBRequest', 'IDBTransaction', 'KeyOperation', 'MediaController', 'MessagePort', 'ModalWindow', 'Notification', 'SVGElementInstance', 'Screen', 'TextTrack', 'TextTrackCue', 'TextTrackList', 'WebSocket', 'WebSocketWorker', 'Worker', 'XMLHttpRequest', 'XMLHttpRequestEventTarget', 'XMLHttpRequestUpload'];

var _wrappedError = null;

var wrap = function wrap(f) {
  try {
    if (typeof f !== 'function' || f._isWrap) {
      return f;
    } else if (!f._wrapped) {
      var wrapped = function wrapped() {
        try {
          for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
          }

          return f.apply(this, args); // eslint-disable-line
        } catch (e) {
          _wrappedError = e;
          throw e;
        }
      };
      wrapped._isWrap = true;
      f._wrapped = wrapped;
      return _extends(wrapped, f);
    }
    return f._wrapped;
  } catch (e) {
    // If anything messes up while trying to wrap the function then don't
    // break the app.
    if (process.env.NODE_ENV === 'production') {
      return f;
    }
    throw e;
  }
};

var unwrap = function unwrap(f) {
  return f && f._wrapped ? f._wrapped : f;
};

var _extendListenerPrototype = function _extendListenerPrototype(listener) {
  var prototype = listener && listener.prototype;
  if (prototype && 'addEventListener' in prototype) {
    var oldAddEventListener = prototype.addEventListener;
    prototype.addEventListener = function (event, callback, bubble) {
      oldAddEventListener.call(this, event, wrap(callback), bubble);
    };
    var oldRemoveEventListener = prototype.removeEventListener;
    prototype.removeEventListener = function (event, callback, bubble) {
      oldRemoveEventListener.call(this, event, unwrap(callback), bubble);
    };
  }
};

var noop = function noop() {};
var oldOnError = window.onerror || noop;
var queue = (0, _util.createErrorQueue)();

var handleError = function handleError(message, url, line, column, error
/* , context */
) {
  var thing = error || _wrappedError;
  _wrappedError = null;
  queue.invoke(thing);
};

window.onerror = function () {
  for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
    args[_key2] = arguments[_key2];
  }

  handleError.apply(undefined, args);
  return oldOnError.apply(window, args);
};

if (typeof window.addEventListener === 'function') {
  // Use the `addEventListener` in supported browsers.
  window.addEventListener('unhandledrejection', function (event) {
    // Bluebird provides `reason` in a differnt property on the error object.
    // See: http://bluebirdjs.com/docs/api/error-management-configuration.html
    if (event.detail && event.detail.reason) {
      queue.invoke(event.detail.reason);
    } else {
      queue.invoke(event.reason);
    }
  });
} else {
  var oldOnUnhandledRejection = window.onunhandledrejection || noop;
  window.onunhandledrejection = function () {
    for (var _len3 = arguments.length, args = Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
      args[_key3] = arguments[_key3];
    }

    queue.invoke(args[0]);
    return oldOnUnhandledRejection.apply(window, args);
  };
}

globals.forEach(function (name) {
  return _extendListenerPrototype(window[name]);
});

exports.default = function (callback) {
  return queue.addListener(callback);
};
"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

var _createBreakpoints = require("../styles/createBreakpoints");

var _withWidth = _interopRequireWildcard(require("../withWidth"));

var _exactProp = _interopRequireDefault(require("../utils/exactProp"));

/**
 * @ignore - internal component.
 */
function HiddenJs(props) {
  var children = props.children,
      only = props.only,
      width = props.width;
  var visible = true; // `only` check is faster to get out sooner if used.

  if (only) {
    if (Array.isArray(only)) {
      for (var i = 0; i < only.length; i += 1) {
        var breakpoint = only[i];

        if (width === breakpoint) {
          visible = false;
          break;
        }
      }
    } else if (only && width === only) {
      visible = false;
    }
  } // Allow `only` to be combined with other props. If already hidden, no need to check others.


  if (visible) {
    // determine visibility based on the smallest size up
    for (var _i = 0; _i < _createBreakpoints.keys.length; _i += 1) {
      var _breakpoint = _createBreakpoints.keys[_i];
      var breakpointUp = props["".concat(_breakpoint, "Up")];
      var breakpointDown = props["".concat(_breakpoint, "Down")];

      if (breakpointUp && (0, _withWidth.isWidthUp)(_breakpoint, width) || breakpointDown && (0, _withWidth.isWidthDown)(_breakpoint, width)) {
        visible = false;
        break;
      }
    }
  }

  if (!visible) {
    return null;
  }

  return children;
}

HiddenJs.propTypes = {
  /**
   * The content of the component.
   */
  children: _propTypes.default.node,

  /**
   * @ignore
   */
  className: _propTypes.default.string,

  /**
   * Specify which implementation to use.  'js' is the default, 'css' works better for server
   * side rendering.
   */
  implementation: _propTypes.default.oneOf(['js', 'css']),

  /**
   * You can use this property when choosing the `js` implementation with server side rendering.
   *
   * As `window.innerWidth` is unavailable on the server,
   * we default to rendering an empty componenent during the first mount.
   * In some situation you might want to use an heristic to approximate
   * the screen width of the client browser screen width.
   *
   * For instance, you could be using the user-agent or the client-hints.
   * https://caniuse.com/#search=client%20hint
   */
  initialWidth: _propTypes.default.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),

  /**
   * If true, screens this size and down will be hidden.
   */
  lgDown: _propTypes.default.bool,

  /**
   * If true, screens this size and up will be hidden.
   */
  lgUp: _propTypes.default.bool,

  /**
   * If true, screens this size and down will be hidden.
   */
  mdDown: _propTypes.default.bool,

  /**
   * If true, screens this size and up will be hidden.
   */
  mdUp: _propTypes.default.bool,

  /**
   * Hide the given breakpoint(s).
   */
  only: _propTypes.default.oneOfType([_propTypes.default.oneOf(['xs', 'sm', 'md', 'lg', 'xl']), _propTypes.default.arrayOf(_propTypes.default.oneOf(['xs', 'sm', 'md', 'lg', 'xl']))]),

  /**
   * If true, screens this size and down will be hidden.
   */
  smDown: _propTypes.default.bool,

  /**
   * If true, screens this size and up will be hidden.
   */
  smUp: _propTypes.default.bool,

  /**
   * @ignore
   * width prop provided by withWidth decorator.
   */
  width: _propTypes.default.string.isRequired,

  /**
   * If true, screens this size and down will be hidden.
   */
  xlDown: _propTypes.default.bool,

  /**
   * If true, screens this size and up will be hidden.
   */
  xlUp: _propTypes.default.bool,

  /**
   * If true, screens this size and down will be hidden.
   */
  xsDown: _propTypes.default.bool,

  /**
   * If true, screens this size and up will be hidden.
   */
  xsUp: _propTypes.default.bool
};
HiddenJs.propTypes = (0, _exactProp.default)(HiddenJs.propTypes);

var _default = (0, _withWidth.default)()(HiddenJs);

exports.default = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var createErrorQueue = exports.createErrorQueue = function createErrorQueue() {
  var queue = [];
  var listeners = [];
  return {
    addListener: function addListener(l) {
      listeners.push(l);
      queue.forEach(function (args) {
        return l.apply(undefined, _toConsumableArray(args));
      });
    },
    invoke: function invoke() {
      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      if (queue.length > 50) {
        queue.shift();
      }
      queue.push(args);
      listeners.forEach(function (l) {
        return l.apply(undefined, args);
      });
    }
  };
};